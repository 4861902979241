import { Provider } from 'urql'

import { createUrqlClient } from 'urql-utils/src/createUrqlClient'

import { correlationId } from './correlationId'

const url = process.env.NEXT_PUBLIC_NEW_GRAPHQL_URL

export const client = createUrqlClient({
  options: {
    url,
    fetchOptions: {
      headers: { 'x-graphql-client-name': 'bluelagoon' },
    },
  },
  correlationId,
})

export const serverSideClient = createUrqlClient({
  options: {
    url,
    fetchOptions: {
      headers: { 'x-graphql-client-name': 'bluelagoon-server' },
    },
  },
  correlationId,
  isServer: true,
})

export const UrqlClientProvider = ({ children }) => {
  return <Provider value={client}>{children}</Provider>
}

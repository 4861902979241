import { useContext } from 'react'
import dynamic from 'next/dynamic'
import { createGlobalStyle } from 'styled-components'

import { colors } from '../constants/colors'
import { CurrencyContext } from '../context/Currency/CurrencyProvider'
import { theme } from '../styles/theme'
import type { PartialBookingEngine } from '../styles/types'
import { mediaObj } from '../utils/media'

const PhoneNumberInput = dynamic(() => import('react-phone-input-2'), {
  ssr: false,
})

// This has been moved to the global level of each project using bl-common, in the future we might want to remove this package
// TODO fix not being able to import: https://github.com/vercel/next.js/issues/19936
// import 'react-phone-input-2/lib/style.css'

const preferredCountries = ['us', 'gb', 'is']
const DEFAULT_COUNTRY = 'is'

type PhoneInputProps = {
  id?: string
  name?: string
  value?: any
  placeholder?: string
  hasError?: boolean
  readOnly?: boolean
  onChange?: (val?: any) => any
  selectedCode?: any
  themeStyle?: PartialBookingEngine['phoneInputField']['input']
  gap: string
  onEnterKeyPress?: () => void
}

const PhoneNumberInputCSS = createGlobalStyle<{
  gap: string
}>`
  .PhoneNumberInput_container {
    position: relative;
    width: 100%;
    padding-bottom: ${theme.spacing[3]};
  }

  .react-tel-input {
    font-family: inherit;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    gap:${props => props.gap};


  .form-control {
    background: var(--color-inputBackground, var(--color-fillPrimary));
    color: var(--color-text);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    color: var(--color-text);
    font-size: 16px;
    height: 56px;
    outline: none;
    padding: ${theme.spacing[0.5]} ${theme.spacing[1]};
    flex: 1 1 80%;
    -webkit-appearance: none;
    transition: box-shadow 150ms, border 150ms;


    ::placeholder {
      opacity: 0.5;
    }

    :hover {
      border: 1px solid var(--color-accent);
      box-shadow: 0 0 12px
        color-mix(in srgb, var(--color-accent) 40%, transparent);
    }

    :focus {
      border: 1px solid var(--color-focus);
      box-shadow: 0 0 12px
        color-mix(in srgb, var(--color-focus) 40%, transparent);
    }

    &[aria-invalid="true"]{
      background: color-mix(in srgb, var(--color-error) 15%, transparent);
      border: 1px solid var(--color-error);

      &:hover,
      &:focus {
        border: 2px solid var(--color-accent);
        box-shadow: 0 0 12px
          color-mix(in srgb, var(--color-accent) 40%, transparent);
      }
    }

    :read-only {
      background: var(--color-inputDisabled);
    }
  }

  .flag-dropdown {
    background: var(--color-inputBackground, var(--color-fillPrimary));
    border: 1px solid var(--border-color);
    position: relative;
    order: -1;
    flex: 0 0 ${theme.spacing[7]};
    transition: box-shadow 150ms, border 150ms;

    border-radius: var(--border-radius);

    ${mediaObj.bmd} {
      flex: 0 0 ${theme.spacing[5]};
    }

    &:hover {
      border: 1px solid var(--color-accent);
      box-shadow: 0 0 12px
        color-mix(in srgb, var(--color-accent) 40%, transparent);
    }

    .selected-flag {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0;
        width: 100%;
        border-radius: var(--border-radius);
        background: var(--color-inputBackground, var(--color-fillPrimary));



      .flag {
          margin-left: -10px;
        }

      .arrow, .arrow.up {
          margin-left: 5px;
          border-top-color: var(--color-accent);
          border-bottom-color: var(--color-accent);
        }
    }
    }

     .flag-dropdown.open {
      border-radius: 0px;
      background: var(--color-inputBackground, var(--color-fillPrimary));

      .selected-flag {
        background: var(--color-inputBackground, var(--color-fillPrimary));
      }
     }

      .PhoneNumberInputDisabled .flag-dropdown {
        text-shadow: 0 0 0 ${colors.formDark};
        background: ${colors.lightGrey};
      }

      .country-list {
        background-color: var(--color-fillPrimary);

        .country:hover {
          background-color: var(--color-background);
        }

        .country.highlight {
          background-color: var(--color-background);
        }
      }
 }
`

export const PhoneInput = ({
  id,
  name,
  value = '',
  placeholder = 'phone number',
  hasError = false,
  readOnly = false,
  onChange = () => {
    /* intentionally empty */
  },
  selectedCode = null,
  gap = theme.spacing[2],
  ...rest
}: PhoneInputProps) => {
  const { countryCode } = useContext(CurrencyContext)
  const handleChange = value => {
    if (value.substr(0, 1) !== '+') {
      value = `+${value}`
    }
    onChange(name)(value)
  }

  const country = selectedCode
    ? selectedCode.toLowerCase()
    : countryCode
      ? countryCode.toLowerCase()
      : DEFAULT_COUNTRY

  return (
    <>
      <PhoneNumberInputCSS gap={gap} />

      <div className={readOnly ? 'PhoneNumberInputDisabled' : undefined}>
        <PhoneNumberInput
          onlyCountries={readOnly ? [country] : undefined}
          inputProps={{
            id,
            name,
            'aria-invalid': hasError,
            readOnly,
          }}
          onChange={handleChange}
          value={value}
          country={country}
          placeholder={placeholder}
          preferredCountries={[country, ...preferredCountries]}
          disabled={readOnly}
          {...rest}
        />
      </div>
    </>
  )
}

import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import type { PartialBookingEngine } from '../../styles/types'

type StyledInputProps = {
  hasError?: boolean
  disableEditing?: boolean
  disableBorderRadiusRightSide?: boolean
  themeStyle?: PartialBookingEngine['inputTextField']['input']
}

export const StyledInput = styled.input<StyledInputProps>`
  background: var(--color-inputBackground, var(--color-fillPrimary));
  border: 1px solid var(--border-color);
  color: var(--color-text);
  font-size: 16px;
  height: 56px;
  outline: none;
  padding: ${({ theme }) => `${theme.spacing[0.5]} ${theme.spacing[1]}`};
  width: 100%;
  -webkit-appearance: none;
  border-top-left-radius: var(--border-topLeftRadius, var(--border-radius));
  border-top-right-radius: var(--border-topRightRadius, var(--border-radius));
  border-bottom-left-radius: var(
    --border-bottomLeftRadius,
    var(--border-radius)
  );
  border-bottom-right-radius: var(
    --border-bottomRightRadius,
    var(--border-radius)
  );
  transition:
    box-shadow 150ms,
    border 150ms;

  ::placeholder {
    opacity: 0.7;
    font-size: 16px;
    color: var(--color-text);
  }

  :hover,
  :focus {
    border: 1px solid var(--color-accent);
    box-shadow: 0 0 12px
      color-mix(in srgb, var(--color-accent) 40%, transparent);
  }

  ${({ hasError }) =>
    hasError &&
    css`
      background: color-mix(in srgb, var(--color-error) 15%, transparent);
      border: 2px solid var(--color-error);

      &:hover,
      &:focus {
        border: 2px solid var(--color-accent);
        box-shadow: 0 0 12px
          color-mix(in srgb, var(--color-accent) 40%, transparent);
      }
    `}

  ${({ disableEditing }) =>
    disableEditing &&
    css`
      color: transparent;
      text-shadow: 0 0 0 ${colors.formDark};
      cursor: pointer;
      caret-color: transparent;
    `}

  ${({ readOnly }) =>
    readOnly &&
    css`
      color: transparent;
      text-shadow: 0 0 0 ${colors.formDark};
      background: ${colors.lightGrey} !important;
    `}
`

const noop = () => {
  /* intentionally empty */
}

export const Input = ({
  id = '',
  name = '',
  label = '',
  placeholder = '',
  type = 'text',
  value = type === 'file' ? undefined : '',
  required = false,
  isRequired = false,
  hasError = false,
  disableEditing = false,
  onChange = undefined,
  onFocus = undefined,
  onBlur = undefined,
  themeStyle = undefined,
  disableBorderRadiusRightSide = false,
  ...rest
}) => (
  <StyledInput
    id={id}
    name={name}
    value={value}
    placeholder={placeholder}
    type={type}
    required={required}
    disableEditing={disableEditing}
    hasError={hasError}
    onChange={onChange || noop}
    aria-invalid={hasError}
    aria-label={`${label || name}, ${isRequired ? 'Required,' : ''}`}
    onFocus={onFocus || noop}
    readOnly={disableEditing}
    onBlur={onBlur}
    themeStyle={themeStyle}
    disableBorderRadiusRightSide={disableBorderRadiusRightSide}
    {...rest}
  />
)

Input.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'email', 'password', 'number']),
  placeholder: PropTypes.string,
  disableEditing: PropTypes.bool,
  required: PropTypes.bool,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
}

import { type CSSProperties, useState } from 'react'
import NextImage, { type ImageLoaderProps, type ImageProps } from 'next/image'
import type { Asset } from 'contentful'
import styled from 'styled-components'

const contentfulImageLoader = ({ src, width, quality }: ImageLoaderProps) =>
  `https:${src}?w=${width}&q=${quality || 75}&fm=webp`

export type ContentfulImageProps = Omit<
  ImageProps,
  'src' | 'srcset' | 'loader' | 'alt'
> & {
  image: Asset
  alt?: string
  fadeIn?: boolean
}

const Image = styled(NextImage)<{ $isLoaded: boolean; $priority?: boolean }>(
  ({ $isLoaded, $priority }) => ({
    opacity: $isLoaded || $priority ? 1 : 0,
    transition: 'opacity 0.25s',
  })
)

const PlaceholderImage = styled(NextImage)({
  filter: 'blur(20px)',
  transition: 'opacity 0.5s',
  transform: 'scale(1.1)',
})

const PlaceholderContainer = styled.div<{ $isLoaded: boolean }>(
  ({ $isLoaded }) => ({
    position: 'absolute',
    inset: 0,
    overflow: 'hidden',
    pointerEvents: 'none',

    [PlaceholderImage]: {
      opacity: $isLoaded ? 0 : 1,
    },
  })
)
export const ContentfulImage = ({
  image,
  fill = true,
  priority,
  style,
  sizes = '100vw',
  onLoad,
  fadeIn,
  ...props
}: ContentfulImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const isFill = fill

  const styles: CSSProperties = {
    objectFit: 'cover',
    objectPosition: 'center',
    ...style,
  }

  // Large images automatically get the 'downloads' subdomain. It points to the
  // content delivery API for static assets and does not allow any manipulation
  // like the image API does. So images will be in original size (>20MB), while
  // the 'images' subdomain points to the images API.
  // For more info see: https://github.com/bluelagoonltd/bluelagoon-web/pull/1294
  const imageSrc = (image?.fields?.file?.url ?? '').replace(
    '//downloads.',
    '//images.'
  )

  const imageAlt = image?.fields?.description ?? 'Blue Lagoon'

  const handleLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    onLoad?.(event)
    setIsLoaded(true)
  }

  // imageSrc is required. If it's missing the Image component will throw an error.
  if (!imageSrc) {
    return null
  }

  return (
    <>
      <Image
        src={imageSrc}
        alt={imageAlt}
        priority={priority}
        loader={contentfulImageLoader}
        fill={fill}
        style={styles}
        height={!isFill ? image.fields.file.details.image.height : undefined}
        width={!isFill ? image.fields.file.details.image.width : undefined}
        sizes={sizes}
        onLoad={handleLoad}
        $isLoaded={isLoaded}
        $priority={priority}
        {...props}
      />
      {(!priority || fadeIn) && (
        <PlaceholderContainer $isLoaded={isLoaded}>
          <PlaceholderImage
            src={contentfulImageLoader({
              src: imageSrc,
              width: 80,
              quality: 20,
            })}
            alt=""
            fill
            style={{
              objectFit: styles?.objectFit ?? 'cover',
              objectPosition: styles?.objectPosition ?? 'center',
            }}
            sizes={sizes}
            aria-hidden
          />
        </PlaceholderContainer>
      )}
    </>
  )
}

import { retryExchange, type RetryExchangeOptions } from '@urql/exchange-retry'
import {
  Client,
  cacheExchange,
  type ClientOptions,
  type Exchange,
  fetchExchange,
} from 'urql'
import { v4 as uuidv4 } from 'uuid'

const DEFAULT_UUID = uuidv4()

// This fixes an issue with using localhost with URQL on api routes
const changeLocalhostToIP = (url: string) => {
  const urlObject = new URL(url)
  if (urlObject.hostname === 'localhost') {
    urlObject.hostname = '127.0.0.1'
  }
  return urlObject.toString()
}

export const retryOptions: RetryExchangeOptions = {
  initialDelayMs: 1000,
  maxDelayMs: 15000,
  randomDelay: true,
  maxNumberAttempts: 2,
  retryIf: err => Boolean(err?.networkError),
}

/**
 * Creates a URQL client with the specified options.
 *
 * @param params - The parameters for creating the URQL client.
 * @param params.options - The client options, with 'fetchOptions' and 'exchanges' overrides.
 * @param params.correlationId - Optional correlation ID to be set in the headers of each request.
 * @param params.isServer - Optional flag indicating if the client is being created on the server.
 * @returns A new URQL client instance.
 */
export const createUrqlClient = ({
  options,
  correlationId,
  isServer,
}: {
  options: Omit<ClientOptions, 'fetchOptions' | 'exchanges'> & {
    fetchOptions?: RequestInit
    exchanges?: Exchange[]
  }
  correlationId?: string
  isServer?: boolean
}) => {
  const {
    url,
    // By default we use cacheExchange, retryExchange and fetchExchange
    exchanges = [cacheExchange, retryExchange(retryOptions), fetchExchange],
    fetchOptions = {},
    ...restOfOptions
  } = options
  // We set the correlationId header here so that it is set on every request
  const headers = {
    'X-Correlation-Id': correlationId || DEFAULT_UUID,
    ...(fetchOptions.headers || {}),
  }
  return new Client({
    url: isServer ? changeLocalhostToIP(url) : url,
    exchanges,
    fetchOptions: {
      ...fetchOptions,
      headers,
    },
    ...restOfOptions,
  })
}

import styled, { css } from 'styled-components'
import { media } from '../utils/media'

interface CloseButtonProps {
  small?: boolean
  alwaysWithBackground?: boolean
}

interface LineProps {
  color?: string
}

const background = css`
  background: var(--color-fillPrimary);
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.2);
  height: 47px;
  width: 47px;
  padding: 0;
  ${({ small }: CloseButtonProps) =>
    small &&
    css`
      height: 37px;
      width: 37px;
    `}
`

const Button = styled.button<CloseButtonProps>`
  background: transparent;
  border-radius: 50%;
  border: 0;

  cursor: pointer;
  display: flex;
  height: auto;
  margin: auto;
  user-select: none;
  width: auto;
  padding: ${({ theme }) => theme.spacing[1.5]};

  ${({ alwaysWithBackground }) => alwaysWithBackground && background};

  ${media.md(background)}
`
const Line = styled.div`
  height: 2px;
  margin: auto;
  width: 16px;

  ${media.md(css`
    width: 18px;
  `)}
`

const TopLine = styled(Line)<LineProps>`
  background: ${props => props.color ?? 'var(--color-text, #5b5d62)'};
  transform: translateY(1px) rotateZ(-45deg);
`

const BottomLine = styled(Line)<LineProps>`
  background: ${props => props.color ?? 'var(--color-text, #5b5d62)'};
  transform: translateY(-1px) rotateZ(45deg);
`

const Icon = styled.div`
  margin: auto;
  overflow: visible;
  transform: rotateZ(-90deg);
  transition: transform 200ms ease-out;

  ${Button}:hover & {
    transform: none;
  }
`

export const CloseButton = ({
  onClose,
  small = false,
  color = undefined,
  alwaysWithBackground = false,
}) => (
  <Button
    onClick={onClose}
    aria-label="Close"
    small={small}
    alwaysWithBackground={alwaysWithBackground}
  >
    <Icon aria-hidden={true}>
      <TopLine color={color} />
      <BottomLine color={color} />
    </Icon>
  </Button>
)

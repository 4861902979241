import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { media } from '../../utils/media'

type StyledTextAreaProps = {
  hasError?: boolean
}

const StyledTextArea = styled.textarea<StyledTextAreaProps>`
  background: var(--color-inputBackground, var(--color-fillPrimary));
  border: 1px solid var(--border-color);
  color: var(--color-text);

  font-size: 16px;
  min-height: 90px;
  outline: none;
  padding: ${({ theme }) => theme.spacing[1]};
  width: 100%;
  -webkit-appearance: none;
  transition:
    box-shadow 150ms,
    border 150ms;

  border-top-left-radius: var(--border-topLeftRadius, var(--border-radius));
  border-top-right-radius: var(--border-topRightRadius, var(--border-radius));
  border-bottom-left-radius: var(
    --border-bottomLeftRadius,
    var(--border-radius)
  );
  border-bottom-right-radius: var(
    --border-bottomRightRadius,
    var(--border-radius)
  );

  ::placeholder {
    opacity: 0.7;
    font-size: 16px;
    color: var(--color-text);
  }

  :hover,
  :focus {
    border: 1px solid var(--color-accent);
    box-shadow: 0 0 12px
      color-mix(in srgb, var(--color-accent) 40%, transparent);
  }

  ${({ hasError }) =>
    hasError &&
    css`
      background: color-mix(in srgb, var(--color-error) 15%, transparent);
      border: 2px solid var(--color-error);

      &:hover,
      &:focus {
        border: 2px solid var(--color-accent);
        box-shadow: 0 0 12px
          color-mix(in srgb, var(--color-accent) 40%, transparent);
      }
    `}

  ${media.md(css`
    min-height: 112px;
  `)}
`

export const TextArea = ({
  id,
  name,
  value = '',
  label = '',
  placeholder,
  required = false,
  isRequired = false,
  hasError = false,
  onChange = undefined,
  ...rest
}) => {
  return (
    <StyledTextArea
      id={id}
      name={name}
      value={value}
      placeholder={placeholder}
      required={required}
      hasError={hasError}
      onChange={
        onChange ||
        (() => {
          /* intentionally empty */
        })
      }
      aria-invalid={hasError}
      aria-label={`${label || name}, ${isRequired ? 'Required' : ''}`}
      {...rest}
    />
  )
}

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
}

import {
  documentToReactComponents,
  type Options,
} from '@contentful/rich-text-react-renderer'
import {
  BLOCKS,
  type Document,
  INLINES,
  MARKS,
} from '@contentful/rich-text-types'
import styled, { css } from 'styled-components'

import { sentryLogging } from 'sentry-utils/logging'

import { colors } from '../constants/colors'
import { Amount } from '../elements/Amount'
import { Button } from '../elements/Button/Button'
import { Type } from '../elements/Typography/Typography'
import { media } from '../utils/media'
import {
  AccordionBlock,
  BulletList,
  HyperLink,
  InfoColumn,
  KeyValuePair,
  LightBoxLink,
  MinimalList,
  RichTextListItem,
  Table,
} from './RichTextBlocks'

interface RichTextRendererProps {
  document: Document
  customOptions?: Options
}

export const DocumentWrapper = styled.div`
  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child,
  p:first-child,
  strong:first-child,
  u:first-child,
  b:first-child,
  i:first-child,
  em:first-child {
    margin-top: 0;
  }

  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing[4]};

    ${media.md(css`
      margin-top: 0;
    `)}
  }
`

// Because Rich text quotes its used for this style, and in that case there is always put an extra p inside which otherwise overwrites the wrapper's styles
const SubtitleResetContent = styled.span`
  p {
    font-size: unset;
    font-weight: unset;
    line-height: unset;
  }
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.lightGrey};

  ${({ theme }) => css`
    margin-top: ${theme.spacing[2]};
    margin-bottom: ${theme.spacing[2]};
  `}
`

// RICH TEXT TO HTML MAPPING DOCUMENTATION:
// Normal text    = p, Normal text
// H1             = H1, Heading 1
// H2             = H2, Heading 2
// H3             = H3, Heading 3
// H4             = H4, Heading 4
// H5             = p, Larger than normal text
// H6             = p, Smaller than normal text
// UL             = ul, List with bulleted items
// OL             = ol, List with bulleted items (look into changing later to something new)
// Blockquote     = p, Large thin text
// HR             = div, Horizontal spacing line
// Hyperlink      = a, Link to URL with styling
// Bold           = strong, bolded text
// Italic         = i, italic text
// Underline      = u, underlined text
// Code           = code, monospaced text

// TODOS:
// BLOCKS.EMBEDDED_ENTRY        = TODO Entry
// BLOCKS.EMBEDDED_ASSET        = TODO Asset
// INLINES.EMBEDDED_ENTRY       = TO continue adding, Inline entry
// INLINES.ENTRY_HYPERLINK      = TODO Link to entry
// INLINES.ASSET_HYPERLINK      = TODO Link to asset

const options = {
  renderMark: () => ({
    [MARKS.BOLD]: children => <strong>{children}</strong>,
    [MARKS.UNDERLINE]: children => <u>{children}</u>,
    [MARKS.ITALIC]: children => <em>{children}</em>,
    [MARKS.CODE]: children => <code>{children}</code>,
  }),
  renderNode: () => ({
    [BLOCKS.DOCUMENT]: (_, children) => (
      <DocumentWrapper>{children}</DocumentWrapper>
    ),
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Type as="p" preset="text" mTop={{ xs: 0.8, md: 1 }}>
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_1]: (_, children) => (
      <Type as="h2" preset="headlineExtraLarge" mTop={{ xs: 1.5, md: 2 }}>
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_2]: (_, children) => (
      <Type as="h2" preset="headlineLarge" mTop={{ xs: 1, md: 1.5 }}>
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <Type as="h3" preset="headlineMedium" mTop={{ xs: 0.8, md: 1 }}>
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_4]: (_, children) => (
      <Type as="h4" preset="headlineSmall" mTop={{ xs: 0.8, md: 1 }}>
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_5]: (_, children) => (
      <Type as="p" preset="textLarge" mTop={{ xs: 1, md: 1.2 }}>
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_6]: (_, children) => (
      <Type as="p" preset="textSmall" mTop={{ xs: 0.5, md: 0.8 }}>
        {children}
      </Type>
    ),
    [BLOCKS.UL_LIST]: (_, children) => <BulletList>{children}</BulletList>,
    [BLOCKS.OL_LIST]: (_, children) => <MinimalList>{children}</MinimalList>,
    [BLOCKS.LIST_ITEM]: (_, children) => (
      <RichTextListItem>{children}</RichTextListItem> // This is used both in UL and OL
    ),
    [BLOCKS.QUOTE]: (_, children) => (
      <Type as="p" preset="subtitle" mTop={{ xs: 0.8, md: 1 }}>
        <SubtitleResetContent>{children}</SubtitleResetContent>
      </Type>
    ),
    [BLOCKS.HR]: () => <Line />,
    [BLOCKS.TABLE]: (_, children) => {
      return (
        <Table>
          <tbody>{children}</tbody>
        </Table>
      )
    },
    [BLOCKS.TABLE_ROW]: (_, children) => <tr>{children}</tr>,
    [BLOCKS.TABLE_CELL]: (_, children) => <td>{children}</td>,
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const { sys, fields } = node.data.target
      switch (sys.contentType.sys.id) {
        case 'ctaButton':
          return (
            <Button
              paddingSize={fields.paddingSize}
              preset={fields.color}
              textColor={fields.textColor}
              to={fields.link}
              maxWidth={{ md: 373 }}
              bottom={{ xs: 1.5, md: 2.5 }}
              top={{ xs: 1.5, md: 2.5 }}
              emailTrigger={fields.emailTrigger}
            >
              {fields.text}
            </Button>
          )
        case 'prices':
          return (
            <Type
              weight="bold"
              color={colors.deepBlue}
              top={{ xs: 1, md: 1 }}
              bottom={{ xs: 1, md: 1 }}
              size={{ xs: 16, md: 16 }}
            >
              <Amount
                value={fields.isk}
                format={fields.priceFormat}
                fallback={null}
                useSymbol
              />
            </Type>
          )
        case 'list':
          return (
            <BulletList>
              {fields.listItems.map(item => (
                <RichTextListItem {...item.fields} key={item.fields.item}>
                  {item.fields.item}
                </RichTextListItem>
              ))}
            </BulletList>
          )
        case 'infoColumn':
          return <InfoColumn {...fields} />
        case 'accordion':
          return <AccordionBlock {...fields} fontSize="small" />
        case 'keyValuePair':
          return <KeyValuePair pair={fields} />
        case 'lightBox':
          return <LightBoxLink {...fields} />
        default:
          return null
      }
    },
    // [BLOCKS.EMBEDDED_ASSET]: (node, children) => (), TODO: Look for these in other renderers

    // [INLINES.EMBEDDED_ENTRY]: (node, children) => (), TODO: Look for these in other renderers
    [INLINES.HYPERLINK]: (node, children) => {
      const {
        data: { uri },
      } = node
      return <HyperLink to={uri}>{children}</HyperLink>
    },
    // [INLINES.ENTRY_HYPERLINK]: (node, children) => (), TODO: Not sure we want/need to support these
    // [INLINES.ASSET_HYPERLINK]: (node, children) => (), TODO: Not sure we want/need to support these
  }),
}

export const RichTextRenderer = ({
  document,
  customOptions = {},
}: RichTextRendererProps) => {
  try {
    const docComponents = documentToReactComponents(document, {
      renderMark: {
        ...options.renderMark(),
        ...(customOptions?.renderMark ?? {}),
      },
      renderNode: {
        ...options.renderNode(),
        ...(customOptions?.renderNode ?? {}),
      },
      renderText: text =>
        customOptions?.renderText ? customOptions.renderText(text) : text,
    })

    return <>{docComponents}</>
  } catch (e) {
    sentryLogging({
      team: 'team-frontend-infrastructure',
      error: new Error('Error rendering in RichTextRenderer', e),
    })
    return null
  }
}

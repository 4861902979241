import { useTranslation } from 'react-i18next'
import type { Options } from '@contentful/rich-text-react-renderer'
import { INLINES } from '@contentful/rich-text-types'
import { AnimatePresence, motion } from 'framer-motion'
import styled, { css, type ThemeProps } from 'styled-components'

import type {
  IExperienceNavigationItemFields,
  INavigationItemFields,
} from 'contentful-shared'
import { triggerEvent } from 'analytics/events'

import { colors } from '../../constants/colors'
import { durations } from '../../constants/durations'
import { LinkArrow } from '../../elements/Arrow/Arrow'
import { ArrowLink } from '../../elements/ArrowLink'
import { Type } from '../../elements/Typography/Typography'
import { useBreakpoints } from '../../hooks/useBreakpoints'
import { useInView } from '../../hooks/useInView'
import { RichTextRenderer } from '../../richText/RichTextRenderer'
import { Appear } from '../../units/Appear'
import { media } from '../../utils/media'
import { mixins } from '../../utils/mixins'
import Card from './Card'

type PortalNavProps = {
  isSubmenu?: boolean
  pages: IExperienceNavigationItemFields[]
  content?: {
    title: string
    description?: string
  }
  promoBox?: INavigationItemFields['promoBox']
  withAnimation?: boolean
  closeMenu?: () => void
  currentPath?: string
}

interface PortalItemProps {
  isBusy: boolean
}
interface PortalSectionProps {
  isSubmenu: boolean
  isBusy: boolean
}

const Back = styled(motion.button)`
  position: relative;
  color: ${colors.deepBlue};
  font-weight: 700;
  font-size: 14px;
  display: none;
  align-items: baseline;
  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.spacing[2]};

  ${media.mlg(css`
    display: flex;
  `)}

  .t-dark & {
    color: ${colors.blueOnDark};

    path {
      fill: ${colors.blueOnDark};
    }
  }

  svg {
    margin-right: 14px;
  }
`

const PortalTitle = styled.div`
  ${media.md(css`
    padding-top: ${({ theme }) => theme.spacing[2]};
  `)};
  ${media.md(css`
    grid-column: span 3;
  `)};
`

const PortalPromoBox = styled.div<{ isMobile: boolean }>`
  ${({ isMobile }) => css`
    padding: ${({ theme }) => theme.spacing[2]};
    background: #91d6e519;
    margin-top: ${({ theme }) => theme.spacing[4]};

    ${media.md(css`
      display: ${isMobile ? 'none' : 'block'};
    `)}
  `}
`

const PortalItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing[2.5]};
  margin-top: ${({ theme }) => theme.spacing[8]};

  ${media.md(css`
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    grid-column: span 9;
    grid-column-gap: 0;
    margin-top: 0;
  `)}
`

const PortalItem = styled.div<PortalItemProps>`
  ${({ isBusy }) => css`
    width: 100%;
    max-width: 400px;
    flex-basis: calc(${isBusy ? '25% - 64px' : '50% - 80px'});

    ${media.md(css`
      height: 100%;
      flex-basis: calc(${isBusy ? '25% - 24px' : '50% - 80px'});
      margin-left: 24px;
    `)};

    ${media.mlg(css`
      flex-basis: calc(${isBusy ? '25% - 64px' : '50% - 80px'});
      margin-left: ${isBusy ? '64px' : '80px'};
    `)};
  `}
`

const PortalContent = styled.div``

const PortalSection = styled.section<PortalSectionProps>`
  position: relative;
  padding: ${({ theme, isSubmenu }) =>
    `${isSubmenu ? 0 : theme.spacing[2.5]} ${theme.spacing[2.5]} ${
      theme.spacing[5]
    } ${theme.spacing[2.5]}`};

  ${media.md(css`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: ${mixins.span({ columns: 0, gutters: 1 })};
    padding: ${({ isSubmenu, theme }: PortalSectionProps & ThemeProps<any>) =>
      isSubmenu ? 0 : `${theme.spacing[2.5]} ${theme.spacing[5]} 0`};

    ${({ isBusy }: PortalSectionProps) =>
      isBusy &&
      css`
        ${PortalTitle}, ${PortalItems} {
          grid-column: span 12;
        }
        ${PortalContent} {
          display: none;
        }
      `}
  `)};

  ${({ isSubmenu }: PortalSectionProps) =>
    !isSubmenu &&
    css`
      @media (min-width: 1600px) {
        padding-left: 120px;
        padding-right: 120px;
      }
      @media (min-width: 2000px) {
        padding-left: 160px;
        padding-right: 160px;
      }
    `}
`

const TabletAndUp = styled.div`
  display: none;

  ${media.md(css`
    display: block;
  `)}
`

const Title = ({ children, withAnimation }) =>
  withAnimation ? (
    <Appear>
      <Type
        as="h2"
        weight="bold"
        size={{ xs: 24, md: 36, lg: 48 }}
        bottom={{ xs: 2 }}
      >
        {children}
      </Type>
    </Appear>
  ) : (
    <Type
      as="h2"
      weight="bold"
      size={{ xs: 24, md: 36, lg: 48 }}
      bottom={{ xs: 2 }}
    >
      {children}
    </Type>
  )

const Description = ({ children, withAnimation }) => (
  <TabletAndUp>
    {withAnimation ? (
      <Appear>
        <Type weight="light" size={{ xs: 18, md: 24 }}>
          {children}
        </Type>
      </Appear>
    ) : (
      <Type weight="light" size={{ xs: 18, md: 24 }}>
        {children}
      </Type>
    )}
  </TabletAndUp>
)

type PromoBoxProps = {
  content: INavigationItemFields['promoBox']
  withAnimation: boolean
  isMobile?: boolean
  title?: string
}

const PromoBox = ({
  content,
  withAnimation,
  isMobile = false,
  title,
}: PromoBoxProps) => {
  const documentOptions: Options = {
    renderNode: {
      [INLINES.HYPERLINK]: ({ data }, children) => {
        if (!data.uri) return
        // Use regex to check if the link is from highlandbase.com or kerlingarfjoll.is
        // If it is we make adjustments to the link for tracking purposes
        const isHighlandBaseLink =
          /:\/\/(?:highlandbase\.com|kerlingarfjoll\.is)/.test(data.uri)
        return (
          <ArrowLink
            to={data.uri}
            {...(isHighlandBaseLink && { rel: 'noopener' })}
            onClick={() => {
              triggerEvent({
                event: 'Navigation Promo Box',
                eventAction: `${data?.uri} - Click`,
                eventLabel: title,
              })
            }}
          >
            {children}
          </ArrowLink>
        )
      },
    },
  }

  return withAnimation ? (
    <Appear>
      <PortalPromoBox isMobile={isMobile}>
        <RichTextRenderer document={content} customOptions={documentOptions} />
      </PortalPromoBox>
    </Appear>
  ) : (
    <PortalPromoBox isMobile={isMobile}>
      <RichTextRenderer document={content} customOptions={documentOptions} />
    </PortalPromoBox>
  )
}

export const PortalNav = ({
  isSubmenu = false,
  pages,
  content,
  promoBox,
  withAnimation = true,
  closeMenu = undefined,
  currentPath = undefined,
}: PortalNavProps) => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpoints()
  const { ref, inView } = useInView()
  const isBusy = pages.length > 2
  const hasSubLinks = pages.some(page => page.subLinks?.length)

  const processImageRatio = () => {
    if (isMobile) {
      return 1.39
    }
    if (hasSubLinks && isSubmenu) {
      return 1.1
    }
    if (isBusy) {
      return 1.5
    }
    return 1.33
  }

  return (
    <AnimatePresence initial={false}>
      <PortalSection isSubmenu={isSubmenu} isBusy={isBusy}>
        <PortalTitle>
          {isSubmenu && inView && (
            <Back
              onClick={closeMenu}
              initial="collapsed"
              animate={inView ? 'open' : 'collapsed'}
              exit="collapsed"
              variants={{
                open: { opacity: 1, x: 0 },
                collapsed: { opacity: 0, x: -50 },
              }}
              transition={{
                duration: 1.2,
                ease: [0.04, 0.62, 0.23, 0.98],
              }}
            >
              <LinkArrow rotate={-180} />
              {t('back')}
            </Back>
          )}
          {content ? (
            <PortalContent ref={ref}>
              <Title withAnimation={withAnimation}>{content.title}</Title>
              <Description withAnimation={withAnimation}>
                {content.description}
              </Description>
            </PortalContent>
          ) : null}
          {!!promoBox && (
            <TabletAndUp>
              <PromoBox
                content={promoBox}
                withAnimation={withAnimation}
                title={content.title}
              />
            </TabletAndUp>
          )}
        </PortalTitle>

        <PortalItems>
          {pages.map((portal, index) => (
            <PortalItem key={portal.title} isBusy={isBusy}>
              {withAnimation ? (
                <Appear>
                  <Card
                    title={portal.title}
                    description={portal.description}
                    image={portal.image}
                    path={portal.primaryCtaUrl}
                    spacingLeft={0}
                    imageRatio={processImageRatio()}
                    headingSize={18}
                    index={index}
                    transitionSpeed={durations.long}
                    closeMenu={closeMenu}
                    currentPath={currentPath}
                    starRating={portal.starRating}
                    subLinks={portal.subLinks}
                  />
                </Appear>
              ) : (
                <Card
                  title={portal.title}
                  description={portal.description}
                  image={portal.image}
                  path={portal.primaryCtaUrl}
                  spacingLeft={isBusy ? 4 : undefined}
                  imageRatio={processImageRatio()}
                  headingSize={18}
                  index={index}
                  transitionSpeed={durations.long}
                  closeMenu={closeMenu}
                  currentPath={currentPath}
                  starRating={portal.starRating}
                  subLinks={portal.subLinks}
                />
              )}
            </PortalItem>
          ))}
        </PortalItems>
        {!!promoBox && (
          <PromoBox
            content={promoBox}
            withAnimation={withAnimation}
            isMobile
            title={content?.title}
          />
        )}
      </PortalSection>
    </AnimatePresence>
  )
}

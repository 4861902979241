import { lighten } from 'polished'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { zIndex } from '../../constants/zIndex'
import { Logo as BlLogo } from '../../elements/Icons/Logo'
import { Link } from '../../elements/Link'
import { Type } from '../../elements/Typography/Typography'
import { media } from '../../utils/media'
import { mixins } from '../../utils/mixins'
export const Logo = styled(BlLogo)`
  margin-bottom: ${({ theme }) => theme.spacing[2.5]};
  width: 130px;
`

export const Form = styled.form`
  position: relative;
`

export const ErrorMessage = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing[-1.5]};
`

export const GroupHeader = styled(Type)`
  padding-top: ${({ theme }) => theme.spacing[2]};
  ${media.md(css`
    padding-top: 0;
  `)};
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  color: var(--color-text);

  ${media.md(css`
    grid-template-columns: 25% 1fr;
    grid-template-rows: 1fr 72px;
  `)};
`

export const StyledLink = styled(Link)`
  display: inline;
  &:hover {
    text-decoration: underline;
  }
`

export const ContactBox = styled.div`
  background: ${colors.darkmodeOffset};
  color: ${colors.light};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mixins.siteGutter};

  padding-top: ${({ theme }) => theme.spacing[3]};
  padding-bottom: ${({ theme }) => theme.spacing[3]};
  z-index: ${zIndex.above};

  ${media.md(css`
    grid-column: 1 / 2;
    grid-row: 1 /3;
    padding: ${({ theme }) =>
      `${theme.spacing[3]} ${theme.spacing[3.5]} ${theme.spacing[4.5]}`};
  `)};
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background: ${colors.lightGrey};
  color: ${colors.midGrey};

  ${mixins.siteGutter};

  padding-top: ${({ theme }) => theme.spacing[1.5]};
  padding-bottom: ${({ theme }) => theme.spacing[3]};

  ${media.md(css`
    grid-template-columns: repeat(3, 1fr);
    grid-column: 2 / 3;
    grid-row: 1 /2;
    padding: ${({ theme }) =>
      `${theme.spacing[3]} ${theme.spacing[3.5]} ${theme.spacing[2]}`};
  `)};
`

export const SignupWrap = styled.div`
  button > p {
    font-size: 16px;
  }
`

export const Bottom = styled.div`
  background: ${colors.white};

  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${({ theme }) => theme.spacing[1.5]};

  ${mixins.siteGutter};

  padding-top: ${({ theme }) => theme.spacing[3]};
  padding-bottom: ${({ theme }) => theme.spacing[8]};

  ${media.md(css`
    grid-template-columns: repeat(3, 1fr);
    grid-column: 2 / 3;
    grid-row: 2 /3;
    padding: ${({ theme }) => `0 ${theme.spacing[3.5]}`};
  `)};
`

export const Group = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: 0 1 50%;
  gap: ${({ theme }) => theme.spacing[0.5]};

  & + & {
    padding-bottom: ${({ theme }) => theme.spacing[2]};
  }

  ${media.md(css`
    & + & {
      padding-bottom: 0;
    }
  `)};
`

export const Secondary = styled.div`
  ${props => css`
    margin-top: ${props.theme.spacing[0.5]};
  `};

  ${StyledLink} {
    color: ${lighten(0.2, colors.dark)};
  }
`

export const IconLink = styled(Link)`
  align-items: center;
  display: flex;
`
export const selectorWrapper = styled.div`
  position: relative;
`

import { sentryLogging } from 'sentry-utils/logging'

import { getCookie } from 'bl-utils/src/cookies'
import type {
  DataLayerObject,
  PageViewEvent,
} from './types/google-analytics-events'
import type { Item } from './types/ecommerce'
import { calcPrice } from 'bl-utils/src/currency/calcPrice'
import { productCatalog } from './productCatalog'

export const triggerEvent = (event: DataLayerObject) => {
  try {
    const gaDebug = getCookie('gaDebug') === 'on'
    if (process.env.NODE_ENV === 'development' || gaDebug) {
      console.log('Google Analytics', event)
    } else {
      window.dataLayer = window.dataLayer || []
      if (Object.hasOwn(event, 'ecommerce'))
        window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push(event)
    }
  } catch (error) {
    if ('event' in event) {
      sentryLogging({
        team: 'team-frontend-infrastructure',
        message: `Error triggering ${event.event} Google Analytics event`,
        extras: { error },
      })
    }
  }
}

// Get calculated price that will return 0 if amount is not provided
const getSafePrice = ({
  amount,
  exchangeRate = 1,
}: {
  amount?: number | null
  exchangeRate?: number
}): number => {
  return calcPrice(amount ?? 0, exchangeRate) ?? 0
}

export type EcommerceEventItemInput = {
  productId: string
  quantity: Item['quantity']
  price?: Item['price'] | null
  discountedPrice?: Item['discount'] | null
  coupon?: Item['coupon'] | null
  index?: number
}

const getCommonEcommerce = ({
  items,
  exchangeRate,
  itemBrand,
  itemCategory,
  itemListId,
  itemListName,
  coupon,
}: {
  items: (EcommerceEventItemInput | null)[]
  itemBrand: Item['item_brand']
  itemCategory: Item['item_category']
  exchangeRate?: number
  itemListId?: Item['item_list_id']
  itemListName?: Item['item_list_name']
  coupon?: Item['coupon']
}) => {
  if (!exchangeRate) {
    sentryLogging({
      team: 'team-frontend-infrastructure',
      message: 'Analytics: Ecommerce event missing exchange rate',
    })
  }
  const filteredItems = items.filter(
    (item): item is EcommerceEventItemInput => item !== null
  )
  const products = filteredItems.map(item => {
    const convertedPrice = getSafePrice({
      amount: item.price,
      exchangeRate,
    })

    const convertedDiscountPrice = getSafePrice({
      amount: item.discountedPrice,
      exchangeRate,
    })

    // This type casting is done to satisfy TS. We will support items that are not found in the catalog.
    // We will log to Sentry if we encounter such items and alert the team we are missing data from our catalog.
    const productLookup =
      productCatalog[item.productId as keyof typeof productCatalog]

    // While we don't want to stop the event from being triggered, we want to know if we are missing data.
    if (!productLookup) {
      sentryLogging({
        team: 'team-frontend-infrastructure',
        message: `Analytics: Product not found in product catalog: ${item.productId}`,
        extras: { item },
      })
    }

    const price =
      // If we have the discounted price, we use that. Otherwise we use the regular price.
      convertedDiscountPrice > 0 ? convertedDiscountPrice : convertedPrice

    const discount =
      convertedDiscountPrice > 0
        ? Math.abs(convertedPrice - convertedDiscountPrice)
        : 0

    return {
      item_id: item.productId,
      item_name: productLookup?.displayName || item.productId,
      // All our prices are in ISK but we convert them to EUR for GA since ISK is not supported.
      currency: 'EUR' as const,
      // Deliberately using loose equality here to check for null and undefined
      ...(item.index != null && { index: item.index }),
      discount,
      price,
      quantity: item.quantity,
      coupon: item.coupon || '',
      item_brand: itemBrand,
      item_category: itemCategory,
      ...(itemListId && { item_list_id: itemListId }),
      ...(itemListName && { item_list_name: itemListName }),
    }
  })

  return {
    // All our prices are in ISK but we convert them to EUR for GA since ISK is not supported.
    currency: 'EUR' as const,
    coupon: coupon || '',
    // The prices will always have some rounding errors due to us converting from ISK to EUR
    // and calculating before rounding the price. It is inevitable and we are not going to try to fix it.
    value: products.reduce(
      (sum, product) => sum + (product.price ?? 0) * product.quantity,
      0
    ),
    items: products,
  }
}

export type EcommerceMeta = {
  exchangeRate: number
  itemBrand: Item['item_brand']
  itemCategory: Item['item_category']
  itemListId?: Item['item_list_id']
  itemListName?: Item['item_list_name']
}

type BaseEcommerceEvent = {
  meta: EcommerceMeta
  items: (EcommerceEventItemInput | null)[]
  paymentType?: string
  transactionId?: string
  coupon?: Item['coupon']
}

export type CommonEcommerceEvent = BaseEcommerceEvent & {
  event:
    | 'view_item'
    | 'add_to_cart'
    | 'remove_from_cart'
    | 'view_cart'
    | 'begin_checkout'
    | 'add_shipping_info'
    | 'select_item'
}

type ViewItemListEcommerceEvent = BaseEcommerceEvent & {
  event: 'view_item_list'
  meta: EcommerceMeta & {
    itemListId: string
    itemListName: string
  }
}

type PaymentInfoEcommerceEvent = BaseEcommerceEvent & {
  event: 'add_payment_info'
  paymentType: string
}

type TransactionEcommerceEvent = BaseEcommerceEvent & {
  event: 'purchase'
  transactionId: string
}

export const triggerEcommerceEvent = ({
  meta,
  event,
  items,
  coupon,
  paymentType,
  transactionId,
}:
  | CommonEcommerceEvent
  | ViewItemListEcommerceEvent
  | PaymentInfoEcommerceEvent
  | TransactionEcommerceEvent) => {
  const { exchangeRate, itemBrand, itemCategory, itemListId, itemListName } =
    meta
  const ecommerceData = getCommonEcommerce({
    items,
    exchangeRate,
    itemBrand,
    itemCategory,
    itemListId,
    itemListName,
    coupon,
  })

  if (event === 'view_item_list') {
    triggerEvent({
      event,
      ecommerce: {
        item_list_id: meta.itemListId,
        item_list_name: meta.itemListName,
        items: ecommerceData.items,
      },
    })
  } else if (event === 'add_payment_info') {
    triggerEvent({
      event,
      ecommerce: {
        payment_type: paymentType,
        ...ecommerceData,
      },
    })
  } else if (event === 'purchase') {
    triggerEvent({
      event,
      ecommerce: {
        transaction_id: transactionId,
        ...ecommerceData,
      },
    })
  } else {
    triggerEvent({
      event,
      ecommerce: ecommerceData,
    })
  }
}

/** Get page category from path. Ignore /is if language is Icelandic */
const getPageCategory = (language: 'is' | 'en') => {
  const [, ...path] = window.location.pathname.split('/')
  if (path[0] === '') return undefined
  return path[language === 'en' ? 0 : 1]
}

/** Get subpage category from path. Ignore /is if language is Icelandic */
const getSubPageCategory = (language: 'is' | 'en') => {
  const [, ...path] = window.location.pathname.split('/')
  if (path[1] === '') return undefined
  return path[language === 'en' ? 1 : 2]
}

export const triggerPageView = (
  event: Omit<
    PageViewEvent,
    'event' | 'pageTitle' | 'pageCategory' | 'pageSubCategory'
  > & { pageSubCategory?: string }
) => {
  if (typeof window !== 'undefined') {
    // Fix issue that sent previous pageTitle instead of new page title when user navigates between pages
    // Delay makes sure that window.document.title has updated before it is sent
    setTimeout(() => {
      const pageViewEvent: PageViewEvent = {
        event: 'globalpv',
        pageTitle: window.document.title,
        pageCategory: getPageCategory(event.language),
        pageSubCategory:
          event.pageSubCategory || getSubPageCategory(event.language),
        ...event,
      }
      triggerEvent(pageViewEvent)
    }, 0)
  }
}

import { createGlobalStyle } from 'styled-components'

import { DINPro } from './fonts'

export const GlobalThemeVariables = createGlobalStyle`
  :root {
    /* Common element values */
    --reach-dialog: 1;
    --border-radius: 0;
    --box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);

    /* Typography */
    --font-family: ${DINPro.style.fontFamily};
    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 600;
    --line-height: 1.5;
    --line-height-heading: 1.15;
  }

  .t-light {
    /* Base Colors */
    --color-accent: #3A7E8D;
    --color-secondary: #52A5B8;
    --color-highlight: #C8EBF2;
    --color-success: #169222;
    --color-warning: #E0BD63;
    --color-error: #DA4B4B;
    --color-info: var(--color-accent);
    --color-gradientUpper: #6793AE;
    --color-gradientLower: #52A5B8;

    /* Backgrounds */
    --color-background: #FAFAFA;
    --color-fillPrimary: #FFFFFF;
    --color-fillPrimaryWithOpacity: color-mix(in srgb, var(--color-fillPrimary) 80%, transparent);
    --color-fillGradient: linear-gradient(
      311deg,
      color-mix(in srgb, var(--color-gradientUpper) 5%, var(--color-fillPrimary)) 2.43%,
      color-mix(in srgb, var(--color-gradientLower) 12%, var(--color-fillPrimary)) 97.27%
    );
    --color-layoutBackground: linear-gradient(90deg, var(--color-gradientUpper) 0%, var(--color-gradientLower) 97.99%);

    /* Buttons */
    --color-buttonPrimaryBackground: var(--color-layoutBackground);
    --color-buttonPrimaryHover: var(--color-accent);
    --color-buttonPrimaryProgressBackground: var(--color-gradientUpper);
    --color-buttonText: #FFFFFF;
    --color-buttonDisabled: #F0F0F0;
    --color-buttonDisabledText: var(--color-text);

    /* UI Elements */
    --border-color: #BFBFBF;
    --color-text: #454647;
    --color-link: var(--color-accent);
    --color-listMarker: var(--color-accent);
    --color-tableRow: var(--color-fillPrimary);
    --color-scrollbar-thumb: var(--color-accent);
    --color-scrollbar-track: #F0F0F0;
    --color-focus: var(--color-accent);

    /* Loading */
    --color-loadingHighlight: var(--color-accent);
    --color-loadingBase: color-mix(in srgb, var(--color-accent) 20%, transparent);

    /* Component specific overrides */

    /* Skeleton */
    --color-skeletonBase: transparent;
    --color-skeletonHighlight: color-mix(in srgb, var(--color-background), black 10%);
  }

  .t-dark {
    /* Base Colors */
    --color-accent: #91D6E5;
    --color-secondary: #C8EBF2;
    --color-highlight: #C8EBF2;
    --color-success: #E8F4E9;
    --color-warning: #E0BD63;
    --color-error: #E9B0B0;
    --color-info: var(--color-accent);
    --color-gradientUpper: #5B5D62;
    --color-gradientLower: #615759;
    
    /* Backgrounds */
    --color-background: #454647;
    --color-fillPrimary: #333333;
    --color-fillPrimaryWithOpacity: color-mix(in srgb, var(--color-fillPrimary) 60%, transparent);
    --color-fillGradient: linear-gradient(
      136deg,
      color-mix(in srgb, var(--color-gradientUpper) 60%, var(--color-fillPrimary)) 1.48%,
      color-mix(in srgb, var(--color-gradientLower) 60%, var(--color-fillPrimary)) 97.66%
    );
    --color-layoutBackground: linear-gradient(90deg, var(--color-gradientUpper) 0%, var(--color-gradientLower) 97.99%);

    /* Buttons */
    --color-buttonPrimaryBackground: linear-gradient(90deg, #6793AE 0%, #52A5B8 97.99%);;
    --color-buttonPrimaryHover: #3A7E8D;
    --color-buttonPrimaryProgressBackground: #6793AE;
    --color-buttonPrimaryText: var(--color-text);
    --color-buttonText: var(--color-fillPrimary);
    --color-buttonDisabled: #5F6871;
    --color-buttonDisabledText: var(--color-text);

    /* UI Elements */
    --border-color: #5B5D62;
    --color-text: #FFFFFF;
    --color-link: var(--color-accent);
    --color-listMarker: var(--color-accent);
    --color-tableRow: color-mix(in srgb, var(--color-fillPrimary) 30%, transparent); 
    --color-scrollbar-thumb: var(--color-accent);
    --color-scrollbar-track: var(--color-fillPrimary);
    --color-focus: var(--color-accent);
    
    /* Loading */
    --color-loadingHighlight: var(--color-accent);
    --color-loadingBase: color-mix(in srgb, var(--color-accent) 20%, transparent);

    /* Component specific overrides */

    /* Skeleton */
    --color-skeletonBase: transparent;
    --color-skeletonHighlight: color-mix(in srgb, var(--color-background), white 10%);
  
    /* SelectTimeRow */
    --color-selectedTimeRowBackground: var(--color-fillPrimary);
  }
`

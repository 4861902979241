import styled from 'styled-components'

const RadioWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`

const Input = styled.input`
  height: 0;
  position: absolute;
  width: 0;
  opacity: 0;

  :checked + label {
    background: var(--color-accent);
    color: var(--color-buttonText);
  }

  :focus + label {
    border: 2px solid var(--color-accent);
  }
`

const Label = styled.label`
  cursor: pointer;
  flex: 1;
  padding: ${({ theme }) => theme.spacing[1]};
  text-align: center;
`

export const Radio = ({
  id,
  name,
  label,
  value,
  checked = false,
  onChange = () => {
    /* intentionally empty */
  },
  hasError = false,
}) => {
  return (
    <RadioWrapper>
      <Input
        id={id}
        name={name}
        type="radio"
        onChange={onChange}
        checked={checked}
        aria-invalid={hasError}
        value={value || label}
      />
      <Label htmlFor={id}>{label}</Label>
    </RadioWrapper>
  )
}

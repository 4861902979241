import styled, { css } from 'styled-components'

interface SelectProps {
  hasError: boolean
  showingPlaceholder: boolean
}

interface SharedProps {
  isRounded: boolean
}

export const Arrow = styled.div`
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
`
export const TextAreaContainer = styled.div<SharedProps>`
  position: relative;
  width: 100%;

  ${({ isRounded }) =>
    !isRounded &&
    css`
      &::after {
        border-bottom: 2px solid var(--color-accent);
        content: '';
        border-left: 2px solid var(--color-accent);
        border-bottom: 2px solid var(--color-accent);
        display: block;
        height: 10px;
        right: 16px;
        position: absolute;
        top: -4px;
        bottom: 0;
        margin: auto;
        transform: rotate(-45deg);
        width: 10px;
      }
    `}
`

export const StyledSelect = styled.select<SelectProps & SharedProps>`
  background: var(--color-inputBackground, var(--color-fillPrimary));
  border: 1px solid var(--border-color);
  color: var(--color-text);
  font-family: inherit;
  font-size: 16px;
  outline: none;
  padding: 0 27px 0 10px;
  width: 100%;
  appearance: none;
  height: 56px;
  cursor: pointer;
  transition:
    box-shadow 150ms,
    border 150ms;
  border-radius: var(--border-radius);

  ${({ isRounded }) =>
    isRounded &&
    css`
      border-radius: 4px;
      height: 30px;
      font-size: 14px;
    `}

  :hover {
    border: 1px solid var(--color-accent);
    box-shadow: 0 0 12px
      color-mix(in srgb, var(--color-accent) 40%, transparent);
  }

  :disabled :hover {
    border: 1px solid var(--border-color);
    box-shadow: none;
    cursor: default;
  }

  :focus {
    border: 1px solid var(--color-focus);
    box-shadow: 0 0 12px
      color-mix(in srgb, var(--color-focus) 40%, transparent);
  }

  ${({ hasError }) =>
    hasError &&
    css`
      background: color-mix(in srgb, var(--color-error) 15%, transparent);
      border: 2px solid var(--color-error);
      :hover {
        border: 2px solid var(--color-accent);
        box-shadow: 0 0 12px
          color-mix(in srgb, var(--color-accent) 40%, transparent);
      }
      :focus {
        border: 2px solid var(--color-focus);
        box-shadow: 0 0 12px
          color-mix(in srgb, var(--color-focus) 40%, transparent);
      }
    `}

  ${({ showingPlaceholder }) =>
    showingPlaceholder &&
    css`
      opacity: 0.5;
    `}

    > option {
    font-family: inherit;
  }
`
